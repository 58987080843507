import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { RouterProvider } from 'react-router-dom';
import { AppRouter } from './router';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAMyDyOeJK14eRFiCsKdyOagt0GRUudx5E",
  authDomain: "mytestfirebase-af709.firebaseapp.com",
  projectId: "mytestfirebase-af709",
  storageBucket: "mytestfirebase-af709.appspot.com",
  messagingSenderId: "1055481716087",
  appId: "1:1055481716087:web:b96624a541386281eb5572",
  measurementId: "G-L4M1432BND"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
