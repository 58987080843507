import { RouterProvider } from "react-router-dom";
import { AppRouter } from "./router";

function App() {
  return (
    <RouterProvider router={AppRouter}></RouterProvider>         
  );
}

export default App;
