import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from "react-router-dom";
import { DashboardPage } from "./pages/dashboard/DashboardPage";
import { StatisticsPage } from "./pages/statistics/StatisticsPage";
import { redirect } from "react-router-dom";
export const AppRouter = createBrowserRouter(
    createRoutesFromElements([
        <Route path="/" element={<Navigate to={"dashboard"}></Navigate>} />,
        <Route path="dashboard" element={<DashboardPage />} />,
        <Route path="statistics" element={<StatisticsPage />} />
    ]

    )
);

